import moment from "moment"

export default class ByTheNumbers {
  constructor() {
    const timers = document.querySelectorAll(".stat--timer")

    timers.forEach((timer) => {
      const number = timer.querySelector(".stat__number-inner")
      const label = timer.querySelector(".stat__label")

      const initialTime = moment()

      this.setText(number, label, initialTime, initialTime)

      setInterval(() => {
        this.setText(number, label, initialTime, moment())
      }, 1000)
    })
  }

  setText(number, label, initialTime, currentTime) {
    const timeDiff = currentTime.diff(initialTime, "seconds")

    let timeSince = 0
    let timeUnit = "seconds"

    if (timeDiff === 1) {
      timeSince = timeDiff
      timeUnit = "second"
    } else if (timeDiff > 1 && timeDiff < 60) {
      timeSince = timeDiff
      timeUnit = "seconds"
    } else if (timeDiff >= 60 && timeDiff < 120) {
      timeSince = 1
      timeUnit = "minute"
    } else if (timeDiff > 60 && timeDiff < 3600) {
      timeSince = Math.floor(timeDiff / 60)
      timeUnit = "minutes"
    } else if (timeDiff >= 3600 && timeDiff < 7200) {
      timeSince = 1
      timeUnit = "hour"
    } else if (timeDiff > 3600) {
      timeSince = Math.floor(timeDiff / 3600)
      timeUnit = "hours"
    }

    number.textContent = timeSince
    label.textContent = timeUnit
  }
}
