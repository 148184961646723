export default class Values {
  constructor() {
    const valueBlocks = document.querySelectorAll(".values")

    valueBlocks.forEach((block) => {
      const valueGrid = block.querySelector(".values__grid")
      const values = block.querySelectorAll(".value")
      const messages = block.querySelector(".values__messages")

      valueGrid.addEventListener("mouseleave", () => {
        values.forEach((value) => {
          value.classList.remove("active")
        })
        messages.childNodes.forEach((message) => {
          message.classList.remove("dim")
        })
      })

      values.forEach((value, index) => {
        value.addEventListener("click", () => {
          this.scrollToMessage(value, index, values, messages)
        })

        value.addEventListener("mouseover", () => {
          this.scrollToMessage(value, index, values, messages)
        })
      })
    })
  }

  scrollToMessage(value, index, values, messages) {
    values.forEach((val) => val.classList.remove("active"))
    value.classList.add("active")

    messages.childNodes.forEach((mess) => mess.classList.add("dim"))

    const message = messages.childNodes[index]
    message.classList.remove("dim")
    const leftScroll = message.offsetLeft

    if (window.innerWidth < 600) {
      messages.scroll({
        left: leftScroll,
        top: 0,
        behavior: "smooth",
      })
    }
  }
}
