export default class Forms {
  constructor() {
    window.initialStyles = this.initialStyles;
    window.styleForm = this.styleForm;
  }

  initialStyles() {
    if ($('body').attr('data-template') === 'contact') {
      var $forms = $('#content form');
      $forms.wrap("<div class='contact-form'></div>")
        .before("<h2>LET'S CHAT</h2>");

      $forms.each(function(index, form) {
        const $form = $(form);

        if (index !== 0) {
          $form.parent('.contact-form').css('display', 'none');
        }
      });
    }
  }

  styleForm() {
    const $forms = $('#content form');

    const fieldTypes = $forms.find('.form-group');
    fieldTypes.each((index, form) => {
      const $form = $(form);
      let label = $form.find("label:not('.radio, .checkbox') > span");

      label.css('display', 'none');
      label = label.text()
        .replace(/\s|[/]/g, '-')
        .replace(/\W/g, '');

      $form.addClass(label);
      $form.find('input, textarea').addClass(`${label}-input`);
    });

    if ($('body').attr('data-template') === 'contact') {
      let formNames = [];

      $forms.each((index, form) => {
        formNames.push($(form).attr('form_name'));
      });

      $forms.each(function(index, form) {
        const $form = $(form);

        let selection = `
          <div class="form-group form-select">
            <a class="button dropdown" aria-controls="form_${index}_dropdown" aria-expanded="false" data-dropdown="form_${index}_dropdown">
              ${$form.attr('form_name')}
            </a>
            <ul aria-hidden="true" class="f-dropdown location-change" data-dropdown-content id="form_${index}_dropdown" tabindex="-1">
        `;

        for (var i = 0; i < formNames.length; i++) {
          if (formNames[i] !== $form.attr('form_name')) {
            selection += `<li>${formNames[i]}</li>`;
          }
        }

        selection += `</ul></div>`;

        if ($form.find('.dropdown.button').length === 0) {
          $form.prepend(selection);
        }
      });

      $('.submit-form').on('click tap', function() {
        if ($('.contact-form').css('display') !== 'none') {
          var $verticalScrollPosition = $('.contact-form').offset().top;
          $("body,html").animate({ scrollTop: $verticalScrollPosition }, 300);
        }
      });

      $forms.find('.form-group input, .form-group textarea').on('keyup', (e) => {
        const target = $(e.currentTarget);
        const className = target.attr('class');
        $(`${className}[type='text']`).val(target.val());
        $(`textarea.${className}`).val(target.val());
      });

      $forms.find('.form-group input[type="radio"]').on('change', (e) => {
        const isChecked = e.currentTarget.checked,
          target = $(e.currentTarget),
          typeSelection = target.parent('label').text();

        $('.radio').each((index, radio) => {
          if (typeSelection == $(radio).text()) {
            $(radio).find('input').prop('checked', isChecked);
          }
        });
      });
    }

    $('.location-change li').on('click', (e) => {
      const selectedForm = $(e.currentTarget).text();

      $forms.each(function(index, form) {
        const target = $(form);

        if (target.attr('form_name') == selectedForm) {
          target.parent('.contact-form')
            .css('display', 'block')
            .siblings('.contact-form')
            .css('display', 'none');
        }
      });
    });
  }
}