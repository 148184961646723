import "@babel/polyfill"
import "slick-carousel"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "jquery.easing"

import Accordions from "./accordions"
import BlokBlok from "./blokblok"
import Capabilities from "./capabilities"
import Clocks from "./clocks"
import Forms from "./forms"
import Galleries from "./galleries"
import Header from "./header"
import Hero from "./hero"
import Maps from "./maps"
import Motion from "./motion"
import Posts from "./posts"
import Services from "./services"
import Site from "./site"
import Staff from "./staff"
import Taglines from "./taglines"
import Timeline from "./timeline"
import Values from "./values"
import Work from "./work"
import ByTheNumbers from "./by-the-numbers"

new Accordions()
new BlokBlok()
new ByTheNumbers()
new Capabilities()
new Clocks()
new Forms()
new Galleries()
new Header()
new Hero()
new Maps()
new Motion()
new Posts()
new Services()
new Site()
new Staff()
new Taglines()
new Timeline()
new Values()
new Work()
