export default class Accordions {
  constructor() {
    const triggers = document.querySelectorAll(".accordion__trigger")

    triggers.forEach((trigger) => {
      const parent = trigger.parentElement
      const content = parent.nextElementSibling

      trigger.addEventListener("click", () => {
        trigger.classList.toggle("open")
        content.toggleAttribute("hidden")
      })
    })
  }
}
