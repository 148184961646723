import * as moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data';

export default class Clocks {
  constructor() {
    this.initClock('#earlier', {
      adjustHours: -6,
      adjustMinutes: -30
    });

    this.initClock('#tomorrow');

    this.initClock('#pittsburgh', {
      timeZone: "America/New_York"
    });

    this.initClock('#honolulu', {
      timeZone: "Pacific/Honolulu"
    });

    this.initClock('#de-ja-vu', {
      adjustMinutes: -8
    });

    this.initClock('#stopped', {
      staticTime: '7:22am'
    });

    $('.location .clock').each((index, clock) => {
      const $clock = $(clock),
        clockId = $clock.attr('id'),
        timezone = $clock.data('timezone');

      this.initClock(`#${clockId}`, {
        timeZone: timezone
      });
    });
  }

  initClock(id, options) {
    let defaultOptions = {
      staticTime: false, // set time to stop clock (ex. 7:22am)
      timeZone: false, // time zone adjustment (timezone names found at: http://momentjs.com/timezone/)
      adjustHours: 0, // add or subtract hours from time
      adjustMinutes: 0 // add or subract minutes from time
    }

    const settings = $.extend({}, defaultOptions, options);

    moment.updateLocale('en', {
      meridiem: function(hour) {
        if (hour < 12) {
          return 'a.m.';
        } else {
          return 'p.m.';
        }
      }
    });

    const target = $(id);
    return target.each((index, clock) => {
      const $clock = $(clock);

      if (settings.staticTime) {
        const stopped = moment(settings.staticTime, "h:mma");
        $clock.find('.time').html(stopped.format('h:mm a'));

        const mRotate = 360 * (stopped.minutes() / 60),
          hRotate = 360 * (parseInt(stopped.format('h')) / 12);

        $clock.find('.minute').css({transform: 'rotate(' + mRotate + 'deg)'});
        $clock.find('.hour').css({ transform: 'rotate(' + hRotate + 'deg)' });

        return false;
      }

      setInterval(() => {
        const time = this.getTime(settings);
        $clock.find('.time').html(time[3]);

        const sRotate = 360 * (time[2] / 60),
          mRotate = 360 * (time[1] / 60),
          hRotate = 360 * (time[0] / 12);

        $clock.find('.second').css({transform: 'rotate(' + sRotate + 'deg)'});
        $clock.find('.minute').css({transform: 'rotate(' + mRotate + 'deg)'});
        $clock.find('.hour').css({transform: 'rotate(' + hRotate + 'deg)'});
      }, 500);
    });
  }

  getTime(settings) {
    let today;

    if (settings.timeZone) {
      today = moment().tz(settings.timeZone);
    } else {
      today = moment();
    }

    if (settings.adjustHours !== 0 || settings.adjustMinutes !== 0) {
      today = moment(today)
        .add(parseInt(settings.adjustHours), 'h')
        .add(parseInt(settings.adjustMinutes), 'm');
    }

    const h = parseInt(today.format('h')),
      m = today.minutes(),
      s = today.seconds(),
      time = today.format("h:mm a");

    return [h, m, s, time];
  }
}