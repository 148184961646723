export default class Taglines {
  constructor() {
    this.taglineList = [
      "Solving Today's Problems with Yesterday's Solutions, Tomorrow.",
      "And remember, there's never a fee until we bill you.",
      "Doing The Bare Maximum Since 1992.",
      "Making stuff up since 1992.",
      "We have computers.",
      "Pittsburgh and Honolulu (obviously)."
    ];

    this.randomize();
  }

  randomize() {
    document.getElementById("tagline").innerHTML =
      this.taglineList[Math.floor(Math.random() * this.taglineList.length)];
  }
}