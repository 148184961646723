import 'jquery.waitforimages';
import Masonry from 'masonry-layout';

export default class Posts {
  constructor() {
    this.postContainer = $('.post-container');

    if (this.postContainer.length > 0) {
      window.postLayout = new Masonry('.post-container', {
        itemSelector: '.post',
        gutter: 10
      });

      window.refreshMasonry = this.refreshMasonry;
      this.refreshMasonry();

      $('#filters a').on('click', (e) => {
        const target = $(e.currentTarget),
          selected = target.text();

        if (selected === 'Recent') {
          $('#filters li:first-of-type').css('display', 'none');
        } else {
          $('#filters li:first-of-type').css('display', 'block');
        }

        $('#selected-filter').text(selected);

        const type = target.data('type'),
          tag = target.data('tag');

        $.ajax({
          url: '/news_posts.js',
          method: 'GET',
          data: {
            tag: tag
          },
          success: () => {},
          error: () => {
            console.error('Failed to load news posts');
          }
        })
      });
    }
  }

  refreshMasonry() {
    $('.post-container').waitForImages(() => {
      postLayout.reloadItems();
      postLayout.layout();
    });
  }
}
