export default class BlokBlok {
  constructor() {
    this.promos = $('.blokblok-promo');
    this.imagesBegin = false;

    this.promos.each((_, promo) => {
      const $promo = $(promo),
        promoImages = $promo.find('> span');

      this.addImageToLogos($promo, promoImages);
    });

    if (this.promos.length) {
      this.initBlinking();
    }
  }

  addImageToLogos(promo, images) {
    let imageSpeed = 4000;

    if (!this.imagesBegin) {
      imageSpeed = 0;
    }

    setTimeout(() => {
      let logos = promo.find('.blokblok-logo:visible');

      if ($(window).width() <= 640) {
        logos = promo.find('.blokblok-promo__left .blokblok-logo:nth-child(2), .blokblok-promo__bottom .blokblok-logo:nth-child(1), .blokblok-promo__right .blokblok-logo:nth-child(7)');
      }

      promo
        .find('.blokblok-logo')
        .removeClass('has-image');

      setTimeout(() => {
        promo.removeAttr('style');

        const selectedImages = this.getRandomImages(images, 3);
        let usedLogos = [];

        selectedImages.forEach((image) => {
          const randomIndex = this.getRandomIndex(logos.length, usedLogos);
          usedLogos.push(randomIndex);

          logos.eq(randomIndex)
            .addClass('has-image')
            .css({
              backgroundImage: `url('${image.dataset.image}')`
            });
        });
      }, 400);

      this.imagesBegin = true;
      this.addImageToLogos(promo, images);
    }, imageSpeed);
  }

  initBlinking() {
    let blinkingSpeed = 2000;

    setTimeout(() => {
      this.promos.each((_, promo) => {
        const $promo = $(promo),
          blokblokLogos = $promo.find('.blokblok-logo');

        const amountToBlink = Math.floor(Math.random() * 2) + 1,
          randomLogoIndex = Math.floor(Math.random() * blokblokLogos.length),
          blinkingLogo = blokblokLogos.eq(randomLogoIndex);

        blinkingLogo.addClass('blinking');

        setTimeout(() => {
          blinkingLogo.removeClass('blinking');
        }, 100);

        if (amountToBlink === 2) {
          const secondRandomLogoIndex = Math.floor(Math.random() * blokblokLogos.length),
            secondBlinkingLogo = blokblokLogos.eq(secondRandomLogoIndex);

          secondBlinkingLogo.addClass('blinking');

          setTimeout(() => {
            secondBlinkingLogo.removeClass('blinking');
          }, 100);
        }
      });

  //     this.blinkingBegin = true;
      this.initBlinking();
    }, blinkingSpeed);
  }

  getRandomIndex(max, used) {
    const newNumber = Math.floor(Math.random() * max);

    if (used.indexOf(newNumber) < 0) {
      return newNumber;
    } else {
      this.getRandomIndex(max, used);
    }
  }

  getRandomImages(images, n) {
    let result = new Array(n),
      len = images.length,
      taken = new Array(len);

    if (n > len) {
      throw new RangeError("getRandom: more elements taken than available");
    }

    while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = images[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}