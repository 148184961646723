import 'dotdotdot';

export default class Motion {
  constructor() {
    window.googleApiClientReady = this.googleApiClientReady;
  }

  googleApiClientReady() {
    const apiKey = 'AIzaSyDrMGQIWKNYg6WebZI3Eqfz6jz03U_cV6k';

    gapi.client.setApiKey(apiKey);
    gapi.client.load('youtube', 'v3', () => {
      const request = gapi.client.youtube.playlistItems.list({
        part: 'snippet',
        playlistId: 'PLmDBVN70yyZpA9M1HCjCwZdVaYuYrdSAy',
        maxResults: 50
      });

      request.execute((response) => {
        const playlist = $('#playlist'),
          firstVid = response.items[0].snippet;

        let currentVideoId = firstVid.resourceId.videoId,
          currentVid = $(`<iframe src="//www.youtube.com/embed/${currentVideoId}?rel=0&showinfo=0&autohide=1" frameborder="0" width="960" height="540">`);

        playlist.find('.video-frame').append(currentVid);

        const items = response.items,
          itemCount = response.items.length;

        playlist.find('.vid-list').width((itemCount * 148));

        for (var i = 0; i < items.length; i++) {
          const videoId = items[i].snippet.resourceId.videoId,
            previewImage = `//img.youtube.com/vi/${videoId}/0.jpg`,
            previewTitle = items[i].snippet.title;

          let itemClass = videoId === currentVideoId ? ' active' : '';
          let itemPreview = `
            <div class="vid-item${itemClass}" data-src="//www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autohide=1">
              <div class="thumb">
                <div class="border"></div>
                <img src="${previewImage}" />
              </div>
              <div class="desc">
                ${previewTitle}
              </div>
            </div>
          `;

          playlist.find('.vid-list').append(itemPreview);
        }

        playlist.find('.desc').dotdotdot();

        $(".vid-item").on('click', (e) => {
          const target = $(e.currentTarget),
            url = target.attr('data-src');

          target.siblings('.vid-item').removeClass('active');
          target.addClass('active');

          playlist.find('iframe').attr('src', url);
        });

        $(".arrows .next").on("click", (e) => {
          e.preventDefault();

          $(".vid-list-items").stop().animate({
            scrollLeft: "+=296"
          }, 750);
        });

        $(".arrows .prev").on("click", (e) => {
          e.preventDefault();

          $(".vid-list-items").stop().animate({
            scrollLeft: "-=296"
          }, 750);
        });
      });
    });
  }
}