import mapboxgl from 'mapbox-gl/dist/mapbox-gl';

export default class Maps {
  constructor() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoid3dkZXZlbG9wZXIiLCJhIjoiY2l6c3dkc3djMDAyaTMzb2RjMjVlMDFnYSJ9.xVmJKduulKh3DuUArC1-jQ';

    $('.map').each((index, map) => {
      const $map = $(map);

      const latitude = parseFloat($map.data('latitude')),
        longitude = parseFloat($map.data('longitude'));

      const lngLat = new mapboxgl.LngLat(longitude, latitude);

      if (latitude && longitude) {
        const myMap = new mapboxgl.Map({
          container: map,
          style: 'mapbox://styles/wwdeveloper/cjo79w3f205a72rqn5afa07f7',
          center: lngLat,
          zoom: 16,
          attributionControl: false,
          scrollZoom: false
        });

        myMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        new mapboxgl.Marker()
          .setLngLat(lngLat)
          .addTo(myMap);
      }
    });
  }
}