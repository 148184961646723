export default class Staff {
  constructor() {
      // Staff Video Controls
    $('.staff-image').on("mouseover click", (e) => {
      const staffVideo = $(e.currentTarget).children('video')[0];

      if (staffVideo) {
        staffVideo.play();
      }
    });
  }
}