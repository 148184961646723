export default class Galleries {
  constructor() {
    const $slide = $(".project-carousel").slick({
      adaptiveHeight: true,
      arrows: true,
      dots: true,
      customPaging: (slick, index) => {
        let targetImage = slick.$slides.eq(index).find("img").attr("src") || ""
        return `<div class="active-border"></div>
          <div class="thumbnail" style="background:url(${targetImage})"></div>`
      },
    })

    $slide.find(".slick-slide").on("click", () => {
      $slide.slick("slickNext")
    })

    window.initCultureSliders = this.initCultureSliders
    this.initCultureSliders()
    this.initTestimonialSliders()
  }

  initCultureSliders() {
    $(".post__media--carousel:not(.slick-initialized)").slick({
      dots: false,
    })
  }

  initTestimonialSliders() {
    const sliders = document.querySelectorAll(".testimonials__slider")

    sliders.forEach((slides) => {
      const nav = slides.nextElementSibling
      const buttons = nav.childNodes

      const slider = $(slides).slick({
        adaptiveHeight: true,
        arrows: true,
      })

      slider.on("beforeChange", (_, slick, currentSlide, nextSlide) => {
        buttons[currentSlide].classList.remove("active")
        buttons[nextSlide].classList.add("active")

        const slides = document.querySelectorAll(".slick-slide")
        slides.forEach((slide) => {
          slide.classList.remove("active-clone")
        })

        if (nextSlide === 0 && currentSlide === slick.$slides.length - 1) {
          const activeClone = slider.find(".slick-current + .slick-cloned")[0]
          activeClone.classList.add("active-clone")
        } else if (
          nextSlide === slick.$slides.length - 1 &&
          currentSlide === 0
        ) {
          const activeClone = slider.find(
            ".slick-cloned:has(+ .slick-current)",
          )[0]
          activeClone.classList.add("active-clone")
        }
      })

      buttons.forEach((btn, index) => {
        btn.addEventListener("click", () => {
          slider.slick("slickGoTo", index)
        })
      })
    })
  }
}
