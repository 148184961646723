export default class Timeline {
  constructor() {
    $('.timeline-navigation a').on('click', (e) => {
      const target = $(e.currentTarget);
      this.timelineNavigation(target);
    });
  }

  timelineNavigation(target) {
    $('.timeline-events .timeline').each((index, timeline) => {
      if (target.attr('data-link') == $(timeline).attr('data-time')) {
        $('html, body').animate({
          scrollTop: $(timeline).offset().top
        }, 1000);

        return false;
      }
    });
  }
}