import 'sticky-kit/dist/sticky-kit';
import 'dotdotdot';

export default class Site {
  constructor() {
    $('.search-field').focus();

    // adds back yellow focus color to nav in ios
    document.addEventListener("touchstart", function(){}, true);

    $('.link-navigation').stick_in_parent({
      parent: '#link-list',
      offset_top: 63
    });

    // Truncate News Titles
    $('.next-post h3, .previous-post h3').dotdotdot({
      watch: 'window',
      wrap: 'letter'
    });

    const nextProject = $('.next-project');

    nextProject.css('display', 'none');
    $(window).on('scroll', () => {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 280) {
        nextProject.css('display', 'block');
      } else {
        nextProject.css('display', 'none');
      }
    });
  }
}