export default class Work {
  constructor() {
    this.listItems = $('.section-nav.az li');

    $('.section-nav.az a').on('click', (e) => {
      this.listItems.removeClass('active');

      const parent = $(e.currentTarget).parent();
      if (!parent.hasClass('disabled')) {
        parent.addClass('active');
      }
    });
  }
}
