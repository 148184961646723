export default class Capabilities {
  constructor() {
    const capabilityImages = document.querySelectorAll(".capability__images")

    capabilityImages.forEach((images, index) => {
      setInterval(() => {
        this.cycleImages(images, index)
      }, 5000)
    })
  }

  cycleImages(images, index) {
    const imageCount = images.childElementCount

    if (imageCount > 1) {
      const shownImage = Array.from(images.childNodes).find((child) =>
        child.classList.contains("show"),
      )
      const shownIndex = this.getChildElementIndex(shownImage)
      const nextIndex = shownIndex === imageCount - 1 ? 0 : shownIndex + 1
      setTimeout(() => {
        shownImage.classList.remove("show")
        images.childNodes[nextIndex].classList.add("show")
      }, index * 250)
    }
  }

  getChildElementIndex(node) {
    return Array.prototype.indexOf.call(node.parentNode.children, node)
  }
}
