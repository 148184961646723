export default class Services {
  constructor() {
    this.servicesLinks = $('#services .section-nav li');

    const activeLink = this.servicesLinks.find(`a[data-url='${location.pathname.replace(/(\/)$/, '')}']`);

    if (activeLink.length > 0) {
      this.servicesLinks.removeClass('active');
      activeLink.parent().addClass('active');
    } else {
      this.servicesLinks.first().addClass('active');
    }

    this.servicesLinks.find('a').on('click', (e) => {
      const target = $(e.currentTarget);
      this.changeService(target);
    });

    if (location.pathname.replace(/(\/)$/, '') == '/pages/services') {
      this.initFirstService();
    }

    $(window).on('popstate', (e) => {
      const targetUrl = location.pathname.replace(/(\/)$/, ''),
        target = this.servicesLinks.find(`a[data-url='${targetUrl}']`);

      if (target.length > 0) {
        this.changeService(target, true);
      }
    });
  }

  initFirstService() {
    const target = this.servicesLinks.first().find('a'),
      targetUrl = target.data('url').replace(/(\/)$/, '');

    history.pushState(null, null, targetUrl);

    $.ajax({
      url: `${targetUrl}.js`,
      method: 'GET',
      data: {
        template: 'service',
        init: true
      },
      error: () => {
        console.error('failed to load first service meta data');
      }
    });
  }

  changeService(target, keepUrl) {
    const targetUrl = target.data('url').replace(/(\/)$/, ''),
      targetSlug = target.data('slug');

    if (targetSlug) {
      if (!keepUrl) {
        history.pushState(null, null, targetUrl);
      }

      this.servicesLinks.removeClass('active');
      this.servicesLinks
        .find(`a[data-url='${targetUrl}']`)
        .parent()
        .addClass('active');

      $.ajax({
        url: `${targetUrl}.js`,
        method: 'GET',
        data: {
          template: 'service'
        },
        error: () => {
          console.error('failed to load projects');
        }
      });
    } else {
      location.pathname = targetUrl;
    }
  }
}