export default class Header {
  constructor() {
    this.contentStart = 0;

    this.toggleOverlay();
    this.headerHide();
    this.toggleSearch();
  }

  toggleOverlay() {
    const $header = $('header'),
      $navSection = $('section.nav'),
      $toggle = $('.nav-toggle'),
      $overlay = $('#overlay');

    $toggle.on('click', (e) => {
      e.preventDefault();

      const $link = $(e.currentTarget);

      if ($header.hasClass('active') && $link.hasClass('close')) {
        $header.removeClass('active');
        $overlay.fadeOut(200, 'easeInOutQuad');

        $('.section-label').fadeIn(200);

        $('body').removeClass('no-scroll');
        $toggle.removeClass('close');

        $('#nav-sections').fadeOut(200, "easeInOutQuad");
        $navSection.animate({opacity: 0}, 200, function() {
          $navSection.css('display', 'none');
        });
      } else {
        $('#main-nav').css('display', 'block');
        $('#search-overlay').css({ display: 'none' });

        $header.addClass('active');

        $('.section-label').fadeOut(200);

        $overlay.fadeIn(200, 'easeInOutQuad');
        $('body').addClass('no-scroll');
        $toggle.addClass('close');

        $navSection
          .addClass('active')
          .css('display', 'block');
        $navSection.animate({opacity: 1}, 200);

        $('#nav-sections').fadeIn(200, 'easeInOutQuad');
      }
    })
  }

  headerHide() {
    let didScroll = false,
      lastScrollTop = 0,
      delta = 5,
      navbarHeight = $('header').outerHeight();

    $(window).on('scroll', () => {
      if ($(window).scrollTop() >= this.contentStart) {
        didScroll = true;
      } else {
        didScroll = true;
      }
    });

    setInterval(function() {
      if (didScroll) {
        hasScrolled();
        didScroll = false;
      }
    }, 500);

    function hasScrolled() {
      var st = $(document).scrollTop();

      if (Math.abs(lastScrollTop - st) <= delta) return;

      if (st > lastScrollTop && st > navbarHeight && !$('#search-overlay .search-field').is(":focus")) {
        $('header').removeClass('nav-down').addClass('nav-up');
      } else if (st + $(window).height() < $(document).height()) {
        $('header').removeClass('nav-up').addClass('nav-down');
      }

      lastScrollTop = st;
    }
  }

  toggleSearch() {
    const toggle = $('#search-toggle'),
      overlay = $('#search-overlay');

    toggle.on('click', () => {
      $('#main-nav').fadeOut(200);
      overlay.addClass('absolute')
        .fadeIn(200, function() {
          overlay.removeClass('absolute');
          overlay.find('.search-field').focus();
        });
    });

    toggle.on('touchstart', () => {
      overlay.find('.search-field').css('border-bottom', '1px solid black');
    })
  }
}